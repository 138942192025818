.svg{
	width:5%;
	height: auto;
	padding-top:2%;
}
.svg1{
	width:10%;
	height: auto;
	padding-top:2%;
}

.logoheader{
	  width: 60%;
    height: 30%;
    margin-bottom: 80%;
}
.logoheader1{
    width: 60%;
    height: 30%;
   /* margin-bottom: 80%;*/
}

.logoscroll{
	width:30%;
	height: auto;
}
.a{
	text-decoration: none;
}
.note{
	padding-right:5%;
	margin-left: 10%;
	font-size: 18px;
	display: flex;
  	justify-content: space-evenly;

}
.timeline {
	padding-left: 33.33%;
}
.timeline1 {
	padding-left: 15%;
}
.timeline2 {
	padding-left: 25%;
	padding-right: 25%;
}
.box{
    position: relative;
    width: 100%;
    display: block;
    color: #5c6770;
    font-size: 14px;
    line-height: 30px;
    padding: 10px 20px;
    height: 52px;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
.datebox{
	
    position: relative;
    display: block;
    width: 100%;
    font-size: 16px;
    color: #999999;
    line-height: 20px;
    padding: 10px 20px;
    border: 1px solid #e1e1e1;
    background-color: #ffffff;
    font-weight: 400;
    height: 50px;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    transition: all 300ms ease;
    -webkit-appearance: listbox;
}
.appointment{
	padding-left: 10%;
	padding-right: 10%;
}
.label{
	text-align: left;
}
.hover {
  cursor: pointer;
  display: inline-block;
  background: #1370b5;
  color: #ffffff;
 }
 .hover1 {
  cursor: pointer;
  display: inline-block;
  background: #ffffff;
  color: #1370b5;
 }
 .hover2 {
  cursor: pointer;
  display: inline-block;
  background:#222222 ;
  color:#ffffff ;
 }
 .line{ 
  background: whitesmoke;
}
.vertical-timeline.vertical-timeline--two-columns {
  max-width: 150%;
}
.vertical-timeline.vertical-timeline--two-columns {
  width: 100%;
}
.space{
	left: 2% !important;
}
/*.headernav{
  margin-left: 30%;
}*/
/*.stickyheadernav{
  margin-left: 40%;
}*/
.wholeheader{
  padding: 5%;
} 
titleheading{
  display: inline-block;
  color: #1370b5;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5em;
  border-radius: 5px;
}
.titlesub{
  position: relative;
  display: block;
  font-size: 35px;
  line-height: 1.2em;
  color: #25304c;
  font-weight: 700;
  text-transform: capitalize;
}
.slidetext{
  position: relative;
  display: block;
  font-size: 12px;
  line-height: 26px;
  color: #25304c;
  font-weight: 600;
}
.slidercss{
  text-align: left;
  padding-top: 25%;
}
.textslider{
  text-align: left;
  padding-top: 20%;
}
.buttonspace{
 right: 5%;
}
.buttonspace1{
 left:3%;
}
.buttoncss{
  padding-bottom: 15%;
  text-align: left; 

}
.column {
  display: none;
}
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
.service-block .icon1 {
    position: relative;
    display: inline-flex;
    height: 200px;
    width: 200px;
    background-color: #ffffff;
    font-size: 42px;
    color: #1370b5;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    border-radius: 50%;
    border: 1px solid #dddddd;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}
.listnew{
  margin-left: 43%
}

.inner-box1:hover .icon{
  border: 0;
  font-size: 62px;
}

.icon1:after{
  position: absolute;
  left: 0;
  top: 0;
  height: 0%;
  width: 50%;
  background-color: #1370b5;
  content: "";
  opacity: 0;
  z-index: 0;
}

.inner-box1:hover .icon1:after{
  height: 50%;
  opacity: .05;
  transition-delay: 200ms;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 500ms ease;
}
.team-block .name1{
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 700;
  margin-bottom: 50px;
}
.iconfixed{
    display: block;
    height: 43px;
    line-height: 44px;
    transition: .25s;
}
.facilities {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #000000;
  background-color: #ffffff;
  text-align:center;
}
.social-icon-four{
  position: relative;
  display: flex;
  margin-left: 7%;
  margin-bottom: 1%;
}

.social-icon-four li {
  position: relative;
  margin-right: 15px;
}

.social-icon-four li img {
  position: relative;
  display: block;
  font-size: 14px;
  color: #1370b5;
  height: 35px;
  width: 35px;
  border: 1px solid #1370b5;
  line-height: 33px;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.social-icon-four li img:hover {
  background-color: #ff6600;
  border-color: #ff6600;
  border-width: 2px;
  height: 45px;
  width: 45px;
}
.timelineimg{
  height: 40px;
  width: 40px;
}
.skill-section .skill-column1{
  position: relative;
  width: 100%;
  padding: 50px 50px;
}
.scrolllist {
  height: 450px;
  overflow-y: scroll;
}
.rowdropdown{
  content: "";
  display: table;
  clear: both;
}
.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  background-color: #ccc;
  height: 250px;
}

.column a {
  float: none;
  color: black;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.navspace {
    position: relative;
    margin-right: 25px;
    margin-left: 20px;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}

.previous {
  color: black;
  font-size: 300%;
}

.next {
  color: black;
  font-size: 300%;
}
.btn:hover {

  background-color: #1370b5;
  color: white;
}
.btn{
  border-color: black;
}
.errormsg{
  font-size: 20px;
  text-align: center;
  margin-left: 33.33%;
}
.datedesign{
  margin-top: 5%;
  margin-left: 33.33%;
}
.predate{
  margin-right: 33.33%;
}
.nextdate{
  margin-left: 33.33%;
}